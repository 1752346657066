module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Самозанятые.рф","short_name":"Сам.рф","description":"Сайт для самозанятых и работы с ними.⚡️ Удобные сервисы для бизнеса и самозанятых граждан.  Большая 【БИРЖА-МАРКЕТПЛЕЙС】 с заказами и исполнителями. ✔️ Проверка самозанятых по ИНН.","start_url":"/","lang":"ru","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"./src/favicon.png","legacy":false,"cache_busting_mode":"none","theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NMNLBJ6","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
