exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-500-index-tsx": () => import("./../../../src/pages/500/index.tsx" /* webpackChunkName: "component---src-pages-500-index-tsx" */),
  "component---src-pages-acquiring-for-site-tsx": () => import("./../../../src/pages/acquiring-for-site.tsx" /* webpackChunkName: "component---src-pages-acquiring-for-site-tsx" */),
  "component---src-pages-acquiring-tsx": () => import("./../../../src/pages/acquiring.tsx" /* webpackChunkName: "component---src-pages-acquiring-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-check-inn-index-tsx": () => import("./../../../src/pages/check-inn/index.tsx" /* webpackChunkName: "component---src-pages-check-inn-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-elektronniy-koshelek-index-tsx": () => import("./../../../src/pages/elektronniy-koshelek/index.tsx" /* webpackChunkName: "component---src-pages-elektronniy-koshelek-index-tsx" */),
  "component---src-pages-find-selfworkers-index-tsx": () => import("./../../../src/pages/find-selfworkers/index.tsx" /* webpackChunkName: "component---src-pages-find-selfworkers-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-mass-payment-tsx": () => import("./../../../src/pages/mass-payment.tsx" /* webpackChunkName: "component---src-pages-mass-payment-tsx" */),
  "component---src-pages-partner-index-tsx": () => import("./../../../src/pages/partner/index.tsx" /* webpackChunkName: "component---src-pages-partner-index-tsx" */),
  "component---src-pages-selfworkers-tsx": () => import("./../../../src/pages/selfworkers.tsx" /* webpackChunkName: "component---src-pages-selfworkers-tsx" */),
  "component---src-pages-telegram-index-tsx": () => import("./../../../src/pages/telegram/index.tsx" /* webpackChunkName: "component---src-pages-telegram-index-tsx" */),
  "component---src-pages-wallet-index-tsx": () => import("./../../../src/pages/wallet/index.tsx" /* webpackChunkName: "component---src-pages-wallet-index-tsx" */)
}

